/* eslint-disable max-lines */
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {Message} from 'models/room';
import {ToastIconName} from 'components/toasts/ToastIcons';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import {useLocalObservable} from 'mobx-react-lite';
import {AlertBtnType} from 'models/enums/Alert.enum';
import UserRole from 'models/enums/UserRole.enum';
import {User} from 'models/user';
import RoomService from 'services/api/RoomService';
import SocketIoServices from 'services/SocketIoServices';
import appService from 'store/appService';
import modalServices from 'store/modalServices';
import alertService from 'store/alertService';
import roomServices from 'store/roomServices';
import toastService from 'store/toastService';
import userServices from 'store/userServices';
import reportModalService from 'store/reportModalService';

export default () => {
	const {addBlockedUser} = useLocalObservable(() => appService);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);
	const {accessToken} = useLocalObservable(() => userServices);
	const {setSubmenuMessage} = useLocalObservable(() => roomServices);
	const {addToast} = useLocalObservable(() => toastService);
	const {showReportModal} = useLocalObservable(() => reportModalService);
	const {hideVisibleModalUsers} = useLocalObservable(() => modalServices);
	const translations = useL10n();
	const {sendAnalytics} = useAnalytics();

	const blockUser = (user: User) => {
		if (user.name && user.id) {
			showReportModal({ban: {name: user.name, userId: user.id}});
		}
	};

	const unBlockUser = (user: User, externalRoomId: string) => {
		showAlert({
			title: user.name ? translations.alerts.unBlockUserInRoom.title(user.name) : 'User',
			subtitle: translations.alerts.unBlockUserInRoom.subTitle,
			buttons: [
				{
					text: translations.alerts.btns.unblock,
					type: AlertBtnType.DESTRUCTIVE,
					onPress: async () => {
						hideAlert();
						if (SocketIoServices.socket !== null && user.id) {
							SocketIoServices.setBan(user.id, externalRoomId, false);
						}
					},
				},
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onPress: hideAlert,
				},
			],
			closeOnBackdropPress: true,
		});
	};

	const blockUserByUser = (user: User) => {
		showAlert({
			title: user.name ? translations.alerts.blockUserInRoomByUser.title(user.name) : 'User',
			subtitle: translations.alerts.blockUserInRoomByUser.subTitle,
			buttons: [
				{
					text: translations.alerts.btns.block,
					type: AlertBtnType.DESTRUCTIVE,
					onPress: async () => {
						hideAlert();
						if (user?.id) {
							const response = await RoomService.blockUser(user.id, accessToken);
							if (response.status === ResponseStatus.SUCCESS) {
								addBlockedUser(user);
								addToast({
									iconName: ToastIconName.userBlocked,
									liveTime: 3000,
									message: translations.toasts.userBlocked,
									cancellable: true,
								});
								sendAnalytics('Social_ButtonClick_BanConfirm');
							}
						}
					},
				},
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onPress: () => {
						hideAlert();
						sendAnalytics('Social_ButtonClick_BanCancel');
					},
				},
			],
			closeOnBackdropPress: true,
		});
	};

	const messageVisible = (
		message: Message,
		externalRoomId: string,
		foundVisibleMessage: boolean
	) => {
		if (message.talker.user && externalRoomId) {
			if (foundVisibleMessage) {
				showAlert({
					title: message.talker.user.name
						? translations.alerts.hideUserMessages.title(message.talker.user.name)
						: 'User',
					subtitle: translations.alerts.hideUserMessages.subTitle,
					buttons: [
						{
							text: translations.alerts.btns.hide,
							type: AlertBtnType.DESTRUCTIVE,
							onPress: () => {
								if (message.talker.user?.id) {
									SocketIoServices.emitSetMessageVisible(
										message.id,
										externalRoomId,
										!foundVisibleMessage
									);
								}
								hideAlert();
							},
						},
						{
							text: translations.alerts.btns.hideAll,
							type: AlertBtnType.DESTRUCTIVE,
							onPress: () => {
								hideAlert();
								if (message.talker.user.id) {
									SocketIoServices.emitSetMessagesVisible(
										message.talker.user.id,
										externalRoomId,
										!foundVisibleMessage
									);
								}
							},
						},
						{
							text: translations.alerts.btns.cancel,
							type: AlertBtnType.NORMAL,
							onPress: hideAlert,
						},
					],
					closeOnBackdropPress: true,
				});
				return;
			}

			showAlert({
				title: message.talker.user.name
					? translations.alerts.showUserMessages.title(message.talker.user.name)
					: 'User',
				subtitle: translations.alerts.showUserMessages.subTitle,
				buttons: [
					{
						text: translations.alerts.btns.show,
						type: AlertBtnType.SUCCESS,
						onPress: () => {
							if (message.talker.user?.id) {
								SocketIoServices.emitSetMessageVisible(
									message.id,
									externalRoomId,
									!foundVisibleMessage
								);
							}
							hideAlert();
						},
					},
					{
						text: translations.alerts.btns.showAll,
						type: AlertBtnType.SUCCESS,
						onPress: () => {
							if (message.talker.user.id) {
								SocketIoServices.emitSetMessagesVisible(
									message.talker.user.id,
									externalRoomId,
									!foundVisibleMessage
								);
							}
							hideAlert();
						},
					},
					{
						text: translations.alerts.btns.cancel,
						type: AlertBtnType.DESTRUCTIVE,
						onPress: hideAlert,
					},
				],
				closeOnBackdropPress: true,
			});
		}
	};

	const messagesVisible = (user: User, externalRoomId: string, foundVisibleMessage: boolean) => {
		if (user && externalRoomId) {
			if (foundVisibleMessage) {
				showAlert({
					title: user.name ? translations.alerts.hideUserMessages.title(user.name) : 'User',
					subtitle: translations.alerts.hideUserMessages.subTitle,
					buttons: [
						{
							text: translations.alerts.btns.hideAll,
							type: AlertBtnType.NORMAL,
							onPress: () => {
								hideAlert();
								if (user.id) {
									SocketIoServices.emitSetMessagesVisible(
										user.id,
										externalRoomId,
										!foundVisibleMessage
									);
								}
							},
						},
						{
							text: translations.alerts.btns.cancel,
							type: AlertBtnType.NORMAL,
							onPress: hideAlert,
						},
					],
					closeOnBackdropPress: true,
				});
				return;
			}

			showAlert({
				title: user.name ? translations.alerts.showUserMessages.title(user.name) : 'User',
				subtitle: translations.alerts.showUserMessages.subTitle,
				buttons: [
					{
						text: translations.alerts.btns.show,
						type: AlertBtnType.SUCCESS,
						onPress: () => {
							if (user.id) {
								SocketIoServices.emitSetMessagesVisible(
									user.id,
									externalRoomId,
									!foundVisibleMessage
								);
							}
							hideAlert();
						},
					},
					{
						text: translations.alerts.btns.cancel,
						type: AlertBtnType.DESTRUCTIVE,
						onPress: hideAlert,
					},
				],
				closeOnBackdropPress: true,
			});
		}
	};

	const changeUserRole = (user: User, externalRoomId: string, role: UserRole) => {
		showAlert({
			title:
				role === UserRole.SPEAKER
					? translations.alerts.setUnsetSpeakerRole.setTitle(user.name ? user.name : 'User')
					: translations.alerts.setUnsetSpeakerRole.unTitle(user.name ? user.name : 'User'),
			buttons: [
				{
					text:
						role === UserRole.SPEAKER
							? translations.alerts.btns.make
							: translations.alerts.btns.remove,
					type: AlertBtnType.NORMAL,
					onPress: async () => {
						hideAlert();
						if (SocketIoServices.socket !== null && user.id) {
							SocketIoServices.emitSetRole(user.id, externalRoomId, role);
							addToast({
								iconName:
									role === UserRole.SPEAKER
										? ToastIconName.userAddedSpeakers
										: ToastIconName.userRemovedSpeakers,
								liveTime: 3000,
								message:
									role === UserRole.SPEAKER
										? translations.toasts.setSpeakerRoleForUser
										: translations.toasts.unSpeakerRoleForUser,
								cancellable: true,
							});
						}
					},
				},
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.DESTRUCTIVE,
					onPress: hideAlert,
				},
			],
			closeOnBackdropPress: true,
		});
	};

	const removeMessage = (message: Message, externalRoomId: string) => {
		if (message.id && externalRoomId) {
			showAlert({
				title: translations.alerts.removeMessage.title,
				buttons: [
					{
						text: translations.alerts.btns.delete,
						type: AlertBtnType.NORMAL,
						onPress: () => {
							SocketIoServices.emitDeleteMessage(message.id, externalRoomId);
							hideAlert();
						},
					},
					{
						text: translations.alerts.btns.cancel,
						type: AlertBtnType.DESTRUCTIVE,
						onPress: hideAlert,
					},
				],
				closeOnBackdropPress: true,
			});
		}
		setSubmenuMessage(null);
	};

	const muteUser = (message: Message, externalRoomId: string) => {
		hideVisibleModalUsers();
		if (message.talker.user?.id && externalRoomId) {
			SocketIoServices.emitMute(externalRoomId, true, message.talker.user?.id);
		}
		setSubmenuMessage(null);
	};

	const pinMessage = (message: Message, externalRoomId: string) => {
		const messageId = message.id;
		if (externalRoomId) {
			if (message.isPinned) {
				showAlert({
					title: translations.alerts.unPinMessage.title,
					buttons: [
						{
							text: translations.alerts.btns.unpin,
							type: AlertBtnType.NORMAL,
							onPress: () => {
								SocketIoServices.emitPinMessage(externalRoomId, null);
								addToast({
									iconName: ToastIconName.unPin,
									liveTime: 3000,
									message: translations.toasts.messageUnpinned,
									cancellable: true,
								});
								hideAlert();
							},
						},
						{
							text: translations.alerts.btns.cancel,
							type: AlertBtnType.DESTRUCTIVE,
							onPress: hideAlert,
						},
					],
					closeOnBackdropPress: true,
				});
			} else {
				showAlert({
					title: translations.alerts.pinMessage.title,
					buttons: [
						{
							text: translations.alerts.btns.pin,
							type: AlertBtnType.NORMAL,
							onPress: () => {
								SocketIoServices.emitPinMessage(externalRoomId, messageId);
								addToast({
									iconName: ToastIconName.pin,
									liveTime: 3000,
									message: translations.toasts.messagePinned,
									cancellable: true,
								});
								hideAlert();
							},
						},
						{
							text: translations.alerts.btns.cancel,
							type: AlertBtnType.DESTRUCTIVE,
							onPress: hideAlert,
						},
					],
					closeOnBackdropPress: true,
				});
			}
		}
		setSubmenuMessage(null);
	};

	return {
		blockUser,
		unBlockUser,
		blockUserByUser,
		messageVisible,
		messagesVisible,
		changeUserRole,
		removeMessage,
		muteUser,
		pinMessage,
	};
};
