/* eslint-disable max-lines */
import RoomStatus from 'models/enums/RoomStatus.enum';
import TooltipPositoinType from 'models/enums/TooltipPositoinType.enum';
import {AgoraStatus} from 'models/enums/AgoraStatus.enum';
import TooltipType from 'models/enums/TooltipType.enum';
import UserRole from 'models/enums/UserRole.enum';
import appService from 'store/appService';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import controlPanelServices from 'store/controlPanelServices';
import emotionServices from 'store/emotionServices';
import tooltipsServices from 'store/tooltipsServices';
import SocketIoServices from 'services/SocketIoServices';
import AgoraService from 'services/api/AgoraService';
import windowLocalStorage from 'utils/windowLocalStorage';
import useEmotions from 'hooks/useEmotions';
import useAnalytics from 'hooks/useAnalytics';
import useLongPress from 'hooks/useLongPress';
import useL10n from 'l10n/useL10n';
import useOnboarding from 'hooks/useOnboarding';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {CSSTransition} from 'react-transition-group';
import classNames from 'classnames';
import {ReactComponent as IcoHandPlain} from 'assets/svg/ico-hand-plain.svg';
import {ReactComponent as IcoHandPlainYellow} from 'assets/svg/ico-hand-plain-yellow.svg';
import {ReactComponent as IcoMic} from 'assets/svg/ico-mic.svg';
import {ReactComponent as IcoMicYellow} from 'assets/svg/ico-mic-yellow.svg';
import Tooltip from 'components/tooltip/Tooltip';
import Button from 'components/hoc/Button';

import {ChatTextarea} from '../chat';
import ControlPanelLeft from './ControlPanelLeft';
import './controlPanel.scss';

const TRANSITION_DURATION_BUTTONS = 500;
const TIMEOUT_TOGGLE_ACTIVE_EMOTION_BUTTON = 350;
const TIMEOUT_EMOTION_TOOLTIP = 10000;

const LONG_PRESS_DEFAULT_OPTIONS = {
	shouldPreventDefault: true,
	delay: 500,
};

const ControlPanel: FunctionComponent = function ControlPanel() {
	const {controlPanel, tooltips} = useL10n();
	const {onBoardingStageHandler} = useOnboarding();
	const {sendEmotion} = useEmotions();
	const {sendAnalytics} = useAnalytics();

	const watcherswebTooltips = windowLocalStorage.get<Tooltips>('watcherswebTooltips');

	const watcherswebTooltipsEmotion =
		watcherswebTooltips && typeof watcherswebTooltips.emotion !== 'undefined'
			? watcherswebTooltips.emotion
			: true;

	const referenceEmotion = useRef<HTMLDivElement>(null);
	const referenceHand = useRef<HTMLDivElement>(null);
	const emotionButtonTimeout: {current: NodeJS.Timeout | null} = useRef(null);
	const emotionToolTipTimeout: {current: NodeJS.Timeout | null} = useRef(null);
	const timeoutWantSpeakToolTipVisibilityRef: {current: NodeJS.Timeout | null} = useRef(null);
	const [isAgoraInited, setIsAgoraInited] = useState(false);
	const [isEmotionButtonActive, setIsEmotionButtonActive] = useState(false);
	const [emotionToolTipVisibility, setEmotionToolTipVisibility] = useState(false);
	const [wantSpeakToolTipVisibility, setWantSpeakToolTipVisibility] = useState(false);

	const {appReadOnly, appVoice, agoraStatus} = useLocalObservable(() => appService);
	const {userData, isUserExternalIdCorrect} = useLocalObservable(() => userServices);
	const {roomId, updateTalkerMic, myTalker, roomData, talkers} = useLocalObservable(
		() => roomServices
	);
	const {visibleButtons} = useLocalObservable(() => controlPanelServices);
	const {emotions, emotionsVisible, idEmotionActive, toggleEmotionsVisible} = useLocalObservable(
		() => emotionServices
	);
	const {tooltip, toggleTooltip} = useLocalObservable(() => tooltipsServices);

	let isMuted = myTalker?.isMuted;
	const myTalkerIsBanned = !!myTalker?.isBanned;
	const checkVisibleMic = myTalker?.role === UserRole.SPEAKER || myTalker?.isModer;

	const controlPanelBtnEmotionsClasses = classNames(
		'control-panel__btn control-panel__btn--emotions',
		{
			'control-panel__btn--active': isEmotionButtonActive,
		}
	);

	const getSrcByIdEmotionActive = () => {
		const findEmotion = emotions.find(emotion => emotion.id === idEmotionActive);
		return `url('${findEmotion?.srcGif}')`;
	};

	const clearEmotionButtonTimeout = () => {
		if (emotionButtonTimeout.current) {
			clearTimeout(emotionButtonTimeout.current);
			setIsEmotionButtonActive(false);
		}
	};

	const onMutedHandler = () => {
		if (!appReadOnly && isUserExternalIdCorrect && isMuted !== undefined && isAgoraInited) {
			if (userData?.id) {
				updateTalkerMic(userData.id, !isMuted);
			}
			if (userData && userData.id && roomId) {
				SocketIoServices.emitMute(roomId, !isMuted);
			}
			AgoraService.switchMicrophone(!isMuted);
		}
	};

	const onSpeakerRequestHandler = () => {
		if (!appReadOnly && isUserExternalIdCorrect && roomId) {
			SocketIoServices.emitToggleHand(roomId);
		}
	};

	const onToogleEmotionsHandler = () => {
		if (!appReadOnly && isUserExternalIdCorrect && !myTalkerIsBanned) {
			toggleEmotionsVisible(!emotionsVisible);
			setEmotionToolTipVisibility(false);
			if (tooltip?.shown) {
				toggleTooltip(null);
			}
			if (!emotionsVisible) {
				sendAnalytics('Social_ButtonClick_EmojiPanel');
			}
		}
	};

	const onSendEmotionHandler = () => {
		if (!appReadOnly && isUserExternalIdCorrect) {
			const findEmotion = emotions.find(emotion => emotion.id === idEmotionActive);
			if (findEmotion) {
				sendEmotion(findEmotion);
				if (emotionsVisible) {
					toggleEmotionsVisible(false);
				}
				if (emotionButtonTimeout.current) {
					clearEmotionButtonTimeout();
				}
				setIsEmotionButtonActive(true);
				emotionButtonTimeout.current = setTimeout(() => {
					setIsEmotionButtonActive(false);
				}, TIMEOUT_TOGGLE_ACTIVE_EMOTION_BUTTON);
			}
		}
	};

	const longPressEvent = useLongPress(
		onToogleEmotionsHandler,
		onSendEmotionHandler,
		LONG_PRESS_DEFAULT_OPTIONS
	);

	useEffect(() => {
		isMuted = myTalker?.isMuted;
	}, [myTalker?.isMuted]);

	useEffect(() => {
		if (myTalkerIsBanned && emotionsVisible) {
			toggleEmotionsVisible(false);
		}
	}, [myTalkerIsBanned]);

	useEffect(() => {
		if (agoraStatus === AgoraStatus.INITED) {
			setIsAgoraInited(true);
		}
	}, [agoraStatus]);

	useEffect(() => {
		if (
			!myTalker?.isModer &&
			talkers.find(t => t.role === UserRole.SPEAKER) &&
			(!tooltip ||
				!tooltip.shown ||
				(tooltip && tooltip.shown && tooltip.type === TooltipType.INFORMATIONAL)) &&
			(!watcherswebTooltips ||
				(watcherswebTooltips && typeof watcherswebTooltips.wantSpeak === 'undefined'))
		) {
			timeoutWantSpeakToolTipVisibilityRef.current = setTimeout(() => {
				setWantSpeakToolTipVisibility(true);
				windowLocalStorage.set('watcherswebTooltips', {
					...watcherswebTooltips,
					wantSpeak: false,
				});
				if (!tooltip?.shown) {
					toggleTooltip({shown: true, type: TooltipType.TRIGGER});
				}
			}, 3000);
		}
	}, [myTalker, talkers, tooltip]);

	useEffect(() => {
		setTimeout(() => {
			if (watcherswebTooltipsEmotion) {
				windowLocalStorage.set('watcherswebTooltips', {
					...windowLocalStorage.get<Tooltips>('watcherswebTooltips'),
					emotion: false,
				});
			}
			setEmotionToolTipVisibility(watcherswebTooltipsEmotion);
		}, TIMEOUT_EMOTION_TOOLTIP);

		return () => {
			clearEmotionButtonTimeout();

			if (emotionToolTipTimeout.current) {
				clearTimeout(emotionToolTipTimeout.current);
			}

			if (timeoutWantSpeakToolTipVisibilityRef.current) {
				clearTimeout(timeoutWantSpeakToolTipVisibilityRef.current);
			}
		};
	}, []);

	if (
		(roomData && roomData.status === RoomStatus.LIVE) ||
		(roomData &&
			roomData.status === RoomStatus.SOON &&
			!(myTalker && !myTalker.isModer && myTalker.role === UserRole.GUEST))
	) {
		return (
			<div className='control-panel'>
				{appReadOnly && !isUserExternalIdCorrect && <div className='control-panel__onboarding' />}

				{!appReadOnly && isUserExternalIdCorrect && !userData?.isOnboard && (
					<Button
						className='control-panel__onboarding'
						aria-label='control-panel-onboarding'
						onClick={onBoardingStageHandler}
					/>
				)}

				<ControlPanelLeft />

				<div className='control-panel__center'>
					<ChatTextarea visibleButtons={visibleButtons} />
				</div>

				<CSSTransition
					in={visibleButtons || checkVisibleMic}
					timeout={TRANSITION_DURATION_BUTTONS}
					classNames='fade'
					unmountOnExit>
					<div className='control-panel__right'>
						{!myTalker?.isBanned && (
							<CSSTransition
								in={appVoice}
								timeout={TRANSITION_DURATION_BUTTONS}
								unmountOnExit
								classNames='fade'>
								<div className='control-panel__right-group' ref={referenceHand}>
									{checkVisibleMic ? (
										<Button
											className='control-panel__btn'
											disabled={!isAgoraInited}
											onClick={onMutedHandler}>
											{isMuted ? <IcoMic /> : <IcoMicYellow />}
										</Button>
									) : (
										<>
											<Button className='control-panel__btn' onClick={onSpeakerRequestHandler}>
												{myTalker?.hand ? <IcoHandPlainYellow /> : <IcoHandPlain />}
											</Button>

											{wantSpeakToolTipVisibility && (
												<Tooltip
													referenceElement={referenceHand.current}
													position={TooltipPositoinType.TOP}
													text={tooltips.wantSpeak}
													delay={3000}
													callbackOnHide={() => {
														setWantSpeakToolTipVisibility(false);
														if (tooltip && tooltip.shown && tooltip.type === TooltipType.TRIGGER) {
															toggleTooltip(null);
														}
													}}
												/>
											)}
										</>
									)}
								</div>
							</CSSTransition>
						)}

						<CSSTransition
							in={visibleButtons}
							timeout={TRANSITION_DURATION_BUTTONS}
							classNames='fade'
							unmountOnExit>
							<div className='control-panel__btn-container' ref={referenceEmotion}>
								<Button className={controlPanelBtnEmotionsClasses} {...longPressEvent}>
									<span
										className='control-panel__btn-ico'
										style={{backgroundImage: getSrcByIdEmotionActive()}}
									/>
								</Button>

								{emotionToolTipVisibility && (
									<Tooltip
										referenceElement={referenceEmotion.current}
										position={TooltipPositoinType.TOP}
										text={tooltips.emotions}
										delay={3000}
										callbackOnHide={() => {
											setEmotionToolTipVisibility(false);
											if (tooltip?.shown) {
												toggleTooltip(null);
											}
										}}
									/>
								)}
							</div>
						</CSSTransition>
					</div>
				</CSSTransition>
			</div>
		);
	}

	return (
		<div className='control-panel'>
			<div className='control-panel__stream-ended'>{controlPanel.streamEnded}</div>
		</div>
	);
};

export default observer(ControlPanel);
