import {action, computed, makeObservable, observable} from 'mobx';
import {User} from 'models/user';

class UserServices {
	@observable
	public userData: User | null = null;

	@observable
	public accessToken: UUID = '';

	@observable
	public userId = '';

	@observable
	public userIdDecrypted = '';

	@observable
	public isUserModer: boolean | null = null;

	@action
	public setUserModer = (value: boolean) => {
		this.isUserModer = value;
	};

	@action
	public setUserId = (id: string) => {
		this.userId = id;
	};

	@action
	public setUserIdDecrypted = (id: string) => {
		this.userIdDecrypted = id;
	};

	@action
	public setUserData = (userData: User) => {
		this.userData = userData;
	};

	@action
	public updateUserData = (data: User) => {
		this.userData = {...this.userData, ...data};
	};

	@action
	public setAccessToken = (value: UUID) => {
		this.accessToken = value;
	};

	@action
	public clearUserData = () => {
		this.userData = null;
		this.accessToken = '';
		this.userId = '';
	};

	@computed
	get isUserExternalIdCorrect() {
		return this.userData && this.userData.externalId !== '0';
	}

	constructor() {
		makeObservable(this);
	}
}

export default new UserServices();
