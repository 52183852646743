import {avatars} from 'data/avatars';
import {getRandomInt} from 'utils/getRandom';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {Avatar} from 'models/user';
import UserService from 'services/api/UserService';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import {FunctionComponent, useCallback, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import './avatarForm.scss';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import modalServices from 'store/modalServices';
import {OnboardStage} from 'models/enums/OnboardStage.enum';
import Button from 'components/hoc/Button';

interface IAvatarFormProps {
	setCurrentScreen?: () => void;
	currentScreen: string;
}

const AvatarForm: FunctionComponent<IAvatarFormProps> = function AvatarForm(props) {
	const {setCurrentScreen, currentScreen} = props;
	const [selectedAvatar, setSelectedAvatar] = useState<null | {
		id: number;
		name: string;
		src: string;
	}>(currentScreen === 'profile' ? null : avatars[getRandomInt(0, avatars.length - 1)]);
	const watcherswebUser = localStorage.getItem('watcherswebUser');
	const translations = useL10n();
	const {sendAnalytics} = useAnalytics();

	const {toggleOnboardModal} = useLocalObservable(() => modalServices);
	const {accessToken, userData, setUserData} = useLocalObservable(() => userServices);
	const {talkers, updateTalker, updateUserMessagesByUserId} = useLocalObservable(
		() => roomServices
	);

	const findIdActiveAvatar = (incomingAvatar: Avatar) => {
		const getNameAvatar = (src: string) => src.split('/').pop()?.split('.')[0];
		return (
			userData?.pic &&
			getNameAvatar(userData.pic) &&
			getNameAvatar(incomingAvatar.src) &&
			getNameAvatar(userData.pic) === getNameAvatar(incomingAvatar.src)
		);
	};

	const onClickOnSaveHandler = async () => {
		if (selectedAvatar !== null) {
			const response = await UserService.patchUserData(
				{defaultPic: selectedAvatar.name, isOnboard: true, onboardStage: OnboardStage.CHAT},
				accessToken
			);
			if (response.status === ResponseStatus.SUCCESS && watcherswebUser) {
				const updateUserData = {
					...JSON.parse(watcherswebUser),
					pic: response.data.pic,
					isOnboard: true,
					onboardStage: OnboardStage.CHAT,
				};
				localStorage.setItem('watcherswebUser', JSON.stringify(updateUserData));
				setUserData(updateUserData);

				if (setCurrentScreen) {
					if (userData) {
						const findTalker = talkers.find(t => t.user.id === userData.id);

						if (userData.id) {
							updateUserMessagesByUserId(userData.id, 'pic', response.data.pic);
						}

						if (findTalker) {
							updateTalker({
								...findTalker,
								user: {
									...findTalker.user,
									pic: response.data.pic,
									isOnboard: true,
									onboardStage: OnboardStage.CHAT,
								},
							});
						}
					}

					sendAnalytics('Social_ButtonClick_AvatarChangesSaved');
					setCurrentScreen();
					return;
				}

				sendAnalytics('Social_ButtonClick_AvatarSet');
				localStorage.removeItem('watcherswebIntroScreen');
				toggleOnboardModal(false);
			}
		}
	};

	const renderUserVipStatus = useCallback(() => {
		if (userData) {
			const {isVip, vipStatus} = userData;
			if (isVip && vipStatus) {
				return (
					<div className={`avatar-form__status avatar-form__status--${vipStatus?.toLowerCase()}`}>
						{vipStatus}
					</div>
				);
			}
		}
		return null;
	}, [userData]);

	const renderUserElement = useCallback(
		(item: Avatar) => {
			return (
				<div className='avatar-form__list-item' key={item.id}>
					<div className='avatar-form__list-spacer' />
					<label htmlFor={item.id.toString()} className='avatar-form__item'>
						<input
							type='radio'
							id={item.id.toString()}
							name='avatars-list'
							className='avatar-form__item-input'
							checked={
								item.id === selectedAvatar?.id || (!selectedAvatar && !!findIdActiveAvatar(item))
							}
							onChange={() => setSelectedAvatar(item)}
						/>
						<span className='avatar-form__item-checkmark' />
						<span className='avatar-form__item-img' style={{backgroundImage: `url(${item.src})`}} />
					</label>
				</div>
			);
		},
		[avatars.length, selectedAvatar?.id]
	);

	return (
		<section className='avatar-form'>
			<div className='avatar-form__axis-y'>
				<div className='avatar-form__picked'>
					<div
						className='avatar-form__picked-img'
						style={{
							backgroundImage: `url(${selectedAvatar ? selectedAvatar?.src : userData?.pic})`,
						}}
					/>
					{/* {renderUserVipStatus()} */}
				</div>
				<p className='avatar-form__label'>{translations.avatarScreen.label}</p>
				<div className='avatar-form__list'>{avatars.map(renderUserElement)}</div>
			</div>
			<Button
				disabled={selectedAvatar === null}
				onClick={onClickOnSaveHandler}
				className='btn avatar-form__btn'>
				{translations.btns.save}
			</Button>
		</section>
	);
};

export default observer(AvatarForm);
