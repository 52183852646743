import {filterUsersBtnsData} from 'data/filterUsersBtnsData';
import UserRole from 'models/enums/UserRole.enum';
import LanguageTag from 'models/enums/LanguageTag.enum';
import {Talker, FilterUsersBtn} from 'models/room';
import {MODAL_STYLE} from 'constants/constants';
import roomServices from 'store/roomServices';
import modalServices from 'store/modalServices';
import {useCallback, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import ReactList from 'react-list';
import appService from 'store/appService';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import {AppScreens} from 'models/enums/AppScreens.enum';
import Button from 'components/hoc/Button';
import UserItem from './UserItem';
import './usersModal.scss';

const UsersModal: React.FC = function UsersModal() {
	const [talkersLocal, setTalkersLocal] = useState<Talker[]>([]);
	const [activeFilterBtn, setActiveFilterBtn] = useState(UserRole.ALL);
	const {roomData, talkers} = useLocalObservable(() => roomServices);
	const {appVoice, changeCurrentScreen, language} = useLocalObservable(() => appService);
	const {visibleModalUsers, hideVisibleModalUsers} = useLocalObservable(() => modalServices);
	const translations = useL10n();
	const {sendAnalytics} = useAnalytics();

	const checkVisibleFilter =
		!!talkers.find(t => t.role === UserRole.SPEAKER || t.role === UserRole.ADMIN) &&
		roomData?.isSpeak;

	const onFilterHandler = (btn: FilterUsersBtn) => setActiveFilterBtn(btn.role);

	const onCloseModalHandler = () => {
		sendAnalytics('Social_NavigationClick_UserListClosed');
		hideVisibleModalUsers();
		setActiveFilterBtn(UserRole.ALL);
	};

	const renderFilterBtn = useCallback(
		(btn: FilterUsersBtn) => (
			<div className='users-modal__filter-item' key={btn.id}>
				<Button
					className={`users-modal__filter-btn ${btn.role === activeFilterBtn ? 'active' : ''}`}
					onClick={() => onFilterHandler(btn)}>
					{btn.icon}
					{language === LanguageTag.ru ? btn.title.ru : btn.title.en}
				</Button>
			</div>
		),
		[talkers, activeFilterBtn]
	);

	const renderUser = (index: number, key: number | string) => (
		<UserItem key={key} talker={talkersLocal[index]} />
	);

	useEffect(() => {
		if (talkers.length) {
			const filterTalkers = talkers.filter(
				talker => activeFilterBtn === UserRole.ALL || talker.role === activeFilterBtn
			);
			if (filterTalkers.length) {
				setTalkersLocal(filterTalkers);
				return;
			}
		}
		setTalkersLocal([]);
	}, [talkers, activeFilterBtn]);

	useEffect(() => {
		visibleModalUsers
			? changeCurrentScreen(AppScreens.USERS)
			: changeCurrentScreen(AppScreens.CHAT);
	}, [visibleModalUsers]);

	useEffect(() => {
		if (!roomData?.isSpeak && activeFilterBtn !== UserRole.ALL) {
			setActiveFilterBtn(UserRole.ALL);
		}
	}, [roomData?.isSpeak]);

	return (
		<Modal
			className='modal'
			isOpen={visibleModalUsers}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<section className='users-modal'>
				<Button className='modal__back' onClick={onCloseModalHandler}>
					close
				</Button>
				<p className='users-modal__title'>{translations.chatUsersScreen.title}</p>
				{checkVisibleFilter && appVoice && (
					<div className='users-modal__filter'>{filterUsersBtnsData.map(renderFilterBtn)}</div>
				)}
				<div className='users-modal__body'>
					<div className='users-modal__axis-y'>
						<div className='users-modal__users-list'>
							<ReactList itemRenderer={renderUser} length={talkersLocal.length} type='simple' />
						</div>
					</div>
				</div>
			</section>
		</Modal>
	);
};

export default observer(UsersModal);
