/* eslint-disable no-console */
import {AnalyticsProps, AnalyticsAdditionalProperty} from 'models/room';
// import appService from 'store/appService';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import AnalyticServices from 'services/api/AnalyticServices';
import {useLocation} from 'react-router-dom';
import {useLocalObservable} from 'mobx-react-lite';
import {track} from '@amplitude/analytics-browser';
import {isAndroid, isIOS, isMacOs} from 'react-device-detect';

const useAnalytics = () => {
	const {search} = useLocation();
	const userIdFromUrl = new URLSearchParams(search).get('userId');
	const roomIdFromUrl = new URLSearchParams(search).get('roomId');

	const {userIdDecrypted} = useLocalObservable(() => userServices);
	const {roomId} = useLocalObservable(() => roomServices);
	// const {debugMode} = useLocalObservable(() => appService);

	const checkDataFromUrl = userIdFromUrl && roomIdFromUrl;

	let objProps: AnalyticsProps;

	const getScreenSize = () => `${window.innerWidth}x${window.innerHeight}`;

	const getOsPlatform = () => {
		if (isAndroid) {
			return 'Android';
		}
		if (isIOS || isMacOs) {
			return 'iOS';
		}
		return 'Web';
	};

	const getProps = (additionalProperty?: AnalyticsAdditionalProperty) => {
		if (userIdDecrypted && roomId) {
			objProps = {
				...objProps,
				user_id: userIdDecrypted,
				event_id: roomId,
				screen_size: getScreenSize(),
				platform: getOsPlatform(),
			};

			if (additionalProperty) {
				objProps = {
					...objProps,
					...additionalProperty,
				};
			}

			return objProps;
		}

		if (checkDataFromUrl) {
			return {
				...objProps,
				user_id: userIdDecrypted || userIdFromUrl,
				event_id: roomIdFromUrl,
				screen_size: getScreenSize(),
				platform: getOsPlatform(),
			};
		}

		return objProps;
	};

	const sendAnalytics = (event: string, props?: any) => {
		const project = process.env.REACT_APP_PROJECT ? {project: process.env.REACT_APP_PROJECT} : {};
		if (event && getProps(props)) {
			track(event, getProps(props));
			AnalyticServices.sendTechnicalAnalytics({
				event,
				value: {...getProps(props), ...project},
			});
		}
	};

	return {sendAnalytics};
};

export default useAnalytics;
