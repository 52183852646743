import {USER_UNNAMED} from 'constants/constants';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import useAnalytics from 'hooks/useAnalytics';
import useL10n from 'l10n/useL10n';
import {FunctionComponent, useCallback} from 'react';
import {useLocalObservable} from 'mobx-react-lite';
import AnonimAva from 'assets/images/anonim-ava.jpg';
import {ReactComponent as ModerIcon} from 'assets/svg/moder.svg';
import {ReactComponent as GuestIcon} from 'assets/svg/guest.svg';
import './profile-main.scss';
import Button from 'components/hoc/Button';

interface IProfileMainScreenProps {
	setCurrentScreen: (val: string) => void;
}

const ProfileMainScreen: FunctionComponent<IProfileMainScreenProps> = function ProfileMainScreen({
	setCurrentScreen,
}) {
	const {userData} = useLocalObservable(() => userServices);
	const {myTalker} = useLocalObservable(() => roomServices);
	const translations = useL10n();
	const {sendAnalytics} = useAnalytics();
	const talkerIsModer = !!myTalker?.isModer;

	const renderUserVipStatus = useCallback(() => {
		if (userData) {
			const {isVip, vipStatus} = userData;
			if (isVip && vipStatus) {
				return (
					<div className='profile-main__status'>
						<div
							className={`profile-main__status-item profile-main__status-item--${vipStatus?.toLowerCase()}`}>
							{vipStatus}
						</div>
					</div>
				);
			}
		}
		return null;
	}, [userData]);

	const clickOnchageAvatarHandler = () => {
		sendAnalytics('Social_ButtonClick_AvatarChange');
		setCurrentScreen('avatar');
	};

	const clickOnchageNameHandler = () => {
		sendAnalytics('Social_ButtonClick_NameChange');
		setCurrentScreen('name');
	};

	return (
		<section className='profile-main profile-screens'>
			<p className='profile-screens__title'>{translations.profileScreen.title}</p>
			<div className='profile-main__avatar'>
				<div
					className='profile-main__avatar-image'
					style={{
						backgroundImage: `url(${userData && userData.pic !== '' ? userData.pic : AnonimAva})`,
					}}>
					{renderUserVipStatus()}
				</div>
				<Button className='profile-main__avatar-change' onClick={clickOnchageAvatarHandler}>
					{translations.profileScreen.selectAnotherPic}
				</Button>
			</div>
			<div className='profile-main__name'>
				<p className='profile-main__label'>{translations.profileScreen.chatNameLabel}</p>
				<div className='profile-main__input'>
					<div className='profile-main__input-wrapper'>
						<span className='profile-main__input-text'>
							{userData && userData.name !== '' ? userData.name : USER_UNNAMED}
						</span>
						{talkerIsModer && !userData?.isInvitedGuest && <ModerIcon />}
						{userData?.isInvitedGuest && <GuestIcon />}
					</div>
					<Button className='profile-main__input-edit' onClick={clickOnchageNameHandler}>
						{translations.profileScreen.changeName}
					</Button>
				</div>
			</div>
		</section>
	);
};

export default ProfileMainScreen;
