import {MessageAdvertisement, MessageAdvertisementTemplateData} from 'models/room';
import useAnalytics from 'hooks/useAnalytics';
import {FunctionComponent, CSSProperties} from 'react';

interface IHighlightContainerProps {
	advertisement: MessageAdvertisement;
	templateName: string;
}

const HighlightContainer: FunctionComponent<IHighlightContainerProps> = function HighlightContainer(
	props
) {
	const {templateName, advertisement} = props;
	const {title, text, pic, link, linkText, templatePic, templateData} = advertisement;
	const {sendAnalytics} = useAnalytics();

	const advertisementButtonText = linkText || 'Узнать подробности';

	const onAdvertisementButtonHandler = () => {
		sendAnalytics('Chat_Highlight_Click', {
			hl_content: title || text || '',
			hl_button_text: advertisementButtonText,
		});
	};

	const renderTitle = (textColor?: string) => (
		<div className='highlight__title' style={textColor ? {color: textColor} : undefined}>
			{title}
		</div>
	);

	const renderText = (textColor?: string) => (
		<div className='highlight__text' style={textColor ? {color: textColor} : undefined}>
			{text}
		</div>
	);

	const renderLink = (btnBgColor?: string, btnTextColor?: string) => {
		const styles: CSSProperties = {};
		if (btnBgColor) {
			styles.backgroundColor = btnBgColor;
		}
		if (btnTextColor) {
			styles.color = btnTextColor;
		}
		return (
			<a
				href={link}
				target='_blank'
				rel='noreferrer'
				className='highlight__href'
				onClick={onAdvertisementButtonHandler}
				style={styles || undefined}>
				{advertisementButtonText}
			</a>
		);
	};

	const renderHighlightContainer = () => {
		switch (templateName) {
			case 'CUSTOM_1':
			case 'CUSTOM_2':
			case 'CUSTOM_3':
				return (
					<div className='highlight__container'>
						<div className='highlight__img' style={{backgroundImage: `url(${pic})`}} />
						<div className='highlight__body'>
							{title && renderTitle()}
							{text && renderText()}
							{link && renderLink()}
						</div>
					</div>
				);
			case 'TEMPLATE_1':
			case 'TEMPLATE_3':
			case 'TEMPLATE_4':
				return (
					<div
						className='highlight__container'
						style={
							templateName === 'TEMPLATE_4' && templatePic
								? {backgroundImage: `url(${templatePic})`}
								: undefined
						}>
						{(title || templateName !== 'TEMPLATE_3') && (
							<div
								className='highlight__head'
								style={
									templateName !== 'TEMPLATE_4' && templatePic
										? {backgroundImage: `url(${templatePic})`}
										: undefined
								}>
								{title && renderTitle()}
							</div>
						)}
						{(text || link) && (
							<div className='highlight__body'>
								{text && renderText()}
								{link && renderLink()}
							</div>
						)}
					</div>
				);
			case 'TEMPLATE_8': {
				const templateDataParse: MessageAdvertisementTemplateData = templateData
					? JSON.parse(templateData)
					: {};
				const styles: CSSProperties = {};
				const {
					alignment,
					gradient,
					gradientFrom,
					gradientTo,
					gradientDirection,
					bgColor,
					btnColor,
					textColor,
					btnTextColor,
				} = templateDataParse;
				if (gradient && gradientFrom && gradientTo && gradientDirection) {
					styles.backgroundImage = `linear-gradient(${gradientDirection}, ${gradientTo} 0%, ${gradientFrom} 100%)`;
				}
				if (bgColor) {
					styles.backgroundColor = bgColor;
				}
				return (
					<div
						className={`highlight__container ${
							alignment === 'center' ? 'highlight__container--center' : ''
						}`}
						style={styles || undefined}>
						{title && renderTitle(textColor)}
						{text && renderText(textColor)}
						{link && renderLink(btnColor, btnTextColor)}
					</div>
				);
			}

			case 'CUSTOM_4':
				return (
					<div className='highlight__container'>
						{pic && <img className='highlight__img' src={pic} alt='' />}
						{link && renderLink()}
					</div>
				);

			default:
				return (
					<div
						className='highlight__container'
						style={templatePic ? {backgroundImage: `url(${templatePic})`} : undefined}>
						{title && renderTitle()}
						{text && renderText()}
						{link && renderLink()}
					</div>
				);
		}
	};

	return renderHighlightContainer();
};

export default HighlightContainer;
