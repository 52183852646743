import {PollEditActionType} from 'models/enums/Poll.enum';
import pollServices from 'store/pollServices';
import modalServices from 'store/modalServices';
import useL10n from 'l10n/useL10n';
import usePollAnalytics from 'hooks/usePollAnalytics';
import {FunctionComponent, useCallback} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {ReactComponent as IcoPoll} from 'assets/svg/ico-poll.svg';
import {ReactComponent as IcoEdit} from 'assets/svg/ico-edit.svg';
import {ReactComponent as IcoEnd} from 'assets/svg/ico-pol-end.svg';
import Button from 'components/hoc/Button';

interface IPollToggleSubmenuProps {
	callBackFunctionAfterClick: (value: boolean) => void;
}

const PollToggleSubmenu: FunctionComponent<IPollToggleSubmenuProps> = function PollToggleSubmenu({
	callBackFunctionAfterClick,
}) {
	const {poll: pollTranslate} = useL10n();
	const {poll, pollAlreadyVoted} = useLocalObservable(() => pollServices);
	const {togglePollCreateModalVisible, togglePollEditModalVisible, togglePollModalVisible} =
		useLocalObservable(() => modalServices);
	const {sendPollAnalytics} = usePollAnalytics();

	const onPollSubmenuCreateHandler = () => {
		togglePollCreateModalVisible(true);
		callBackFunctionAfterClick(false);
	};

	const onPollSubmenuEditHandler = () => {
		togglePollEditModalVisible(true);
		callBackFunctionAfterClick(false);
	};

	const onPollSubmenuOpenHandler = () => {
		togglePollModalVisible(true);
		callBackFunctionAfterClick(false);
		pollAlreadyVoted ? sendPollAnalytics('Chat_Quize_View') : sendPollAnalytics('Chat_Quize_Open');
	};

	const onPollSubmenuEndHandler = async () => {
		togglePollEditModalVisible(true, PollEditActionType.END);
		callBackFunctionAfterClick(false);
	};

	const renderSubmenuItems = useCallback(() => {
		if (poll) {
			return (
				<ul className='poll-toggle__submenu-items'>
					<li className='poll-toggle__submenu-item'>
						<Button className='poll-toggle__submenu-button' onClick={onPollSubmenuEditHandler}>
							{pollTranslate.toggleSubmenu.edit}
							<IcoEdit />
						</Button>
					</li>
					<li className='poll-toggle__submenu-item'>
						<Button className='poll-toggle__submenu-button' onClick={onPollSubmenuOpenHandler}>
							{pollTranslate.toggleSubmenu.show}
							<IcoPoll />
						</Button>
					</li>
					<li className='poll-toggle__submenu-item'>
						<Button className='poll-toggle__submenu-button' onClick={onPollSubmenuEndHandler}>
							{pollTranslate.toggleSubmenu.end}
							<IcoEnd />
						</Button>
					</li>
				</ul>
			);
		}
		return (
			<ul className='poll-toggle__submenu-items'>
				<li className='poll-toggle__submenu-item'>
					<Button className='poll-toggle__submenu-button' onClick={onPollSubmenuCreateHandler}>
						{pollTranslate.toggleSubmenu.create}
						<IcoEdit />
					</Button>
				</li>
			</ul>
		);
	}, [poll]);

	return <div className='poll-toggle__submenu'>{renderSubmenuItems()}</div>;
};

export default observer(PollToggleSubmenu);
