import {action, makeObservable, observable} from 'mobx';
import {Bet} from 'models/bet';

class CopybettingServices {
	@observable
	public bets: [Bet[]] = [[]];

	@observable
	public betsError = '';

	@action
	public setBets = (value: Bet[], page: number) => {
		this.bets[page] = [...value];
	};

	@action
	public clearBets = () => {
		this.bets = [[]];
	};

	@action
	public setBetsError = (error: string) => {
		this.betsError = error;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new CopybettingServices();
