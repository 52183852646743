import MessageType from 'models/enums/MessageType.enum';
import {Message} from 'models/room';
import roomServices from 'store/roomServices';
import toastService from 'store/toastService';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import useCopy from 'hooks/useCopy';
import {FunctionComponent, useEffect} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {ToastIconName} from 'components/toasts/ToastIcons';
import Button from 'components/hoc/Button';
import {ReactComponent as CopyIcon} from 'assets/svg/ico-copy.svg';

interface IButtonCopyProps {
	message: Message;
}

const ButtonCopy: FunctionComponent<IButtonCopyProps> = function ButtonCopy({message}) {
	const {chatScreen, toasts} = useL10n();
	const {text, type: messageType, advertisement} = message;

	const {setSubmenuMessage} = useLocalObservable(() => roomServices);
	const {addToast} = useLocalObservable(() => toastService);
	const {sendAnalytics} = useAnalytics();

	const textMessageForCopy = () => {
		if (messageType === MessageType.ADVERTISEMENT && advertisement) {
			const {title: advertisementTitle, text: advertisementText} = advertisement;
			return `${advertisementTitle || ''}${advertisementTitle && advertisementText ? '. ' : ''}${
				advertisementText || ''
			}`;
		}
		return text;
	};

	const {copied, copy} = useCopy(textMessageForCopy());

	const onCopyHandler = async () => {
		if (typeof WatchersChannel !== 'undefined') {
			WatchersChannel.postMessage(
				JSON.stringify({
					type: 'watchersDataExchange',
					body: {action: 'copy', data: {text: textMessageForCopy()}},
				})
			);
			addToast({
				iconName: ToastIconName.copy,
				liveTime: 3000,
				message: toasts.messageCopied,
				cancellable: true,
			});
			sendAnalytics('Social_ButtonClick_MessageCopy');
			setSubmenuMessage(null);
			return;
		}
		await copy();
		setSubmenuMessage(null);
	};

	useEffect(() => {
		if (copied) {
			addToast({
				iconName: ToastIconName.copy,
				liveTime: 3000,
				message: toasts.messageCopied,
				cancellable: true,
			});
			sendAnalytics('Social_ButtonClick_MessageCopy');
		}
	}, [copied]);

	return (
		<Button className='chat__submenu-btn' onClick={onCopyHandler}>
			{chatScreen.chatSubmenu.copy}
			<CopyIcon className='chat__submenu-icon' />
		</Button>
	);
};

export default observer(ButtonCopy);
