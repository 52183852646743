import roomServices from 'store/roomServices';
import {FunctionComponent} from 'react';
import {CSSTransition} from 'react-transition-group';
import {useLocalObservable} from 'mobx-react-lite';
import classNames from 'classnames';
import {ReactComponent as Loader} from 'assets/svg/loader.svg';
import './chat.scss';

interface IChatMessagesPreloaderProps {
	visible: boolean;
}

const ChatMessagesPreloader: FunctionComponent<IChatMessagesPreloaderProps> =
	function ChatMessagesPreloader({visible = false}) {
		const {myTalker, pinnedMessage} = useLocalObservable(() => roomServices);
		const myTalkerIsModer = !!myTalker?.isModer;

		const chatMessageClasses = classNames('chat__messages-preloader', {
			'chat__messages-preloader--down':
				(myTalkerIsModer || !myTalkerIsModer) && pinnedMessage !== null,
		});

		return (
			<CSSTransition in={visible} timeout={500} classNames='fade' unmountOnExit>
				<div className={chatMessageClasses}>
					<Loader />
				</div>
			</CSSTransition>
		);
	};

export default ChatMessagesPreloader;
