import {stickersEmojiBtnsData} from 'data/stickersEmojiBtnsData';
import {MAX_TEXTAREA_LENGTH, MAX_TEXTAREA_LENGTH_FOR_MODERATOR} from 'constants/constants';
import {findValueByKeyInObject} from 'utils/findValueByKeyInObject';
import LanguageTag from 'models/enums/LanguageTag.enum';
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {StickersEmojiControlsBtn, Talker} from 'models/room';
import appService from 'store/appService';
import roomServices from 'store/roomServices';
import chatServices from 'store/chatServices';
import stickersEmojiServices from 'store/stickersEmojiServices';
import modalServices from 'store/modalServices';
import checkRealTextLength from 'utils/checkRealTextLength';
import {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Button from 'components/hoc/Button';
import {StickersPicker} from './stickersPicker';
import './stickersEmojiPicker.scss';

const StickersEmojiViewer: FunctionComponent = function StickersEmojiViewer() {
	const myTalkerRef = useRef<Talker | null>(null);
	const [activeBtn, setActiveBtn] = useState(StickersEmojiType.STICKERS);
	const {language} = useLocalObservable(() => appService);
	const {myTalker} = useLocalObservable(() => roomServices);
	const {setTextareaValue, setVisibleTextareaSendBtn} = useLocalObservable(() => chatServices);
	const {visibleStickersEmoji, stickers, togglleVisibleStickersEmoji} = useLocalObservable(
		() => stickersEmojiServices
	);
	const {isModalOpen} = useLocalObservable(() => modalServices);

	const maxTextareaLength = () =>
		myTalkerRef.current?.isModer ? MAX_TEXTAREA_LENGTH_FOR_MODERATOR : MAX_TEXTAREA_LENGTH;

	const onClickStickersEmojiViewerHandler = () => {
		togglleVisibleStickersEmoji(false);
	};

	const onEmojiSelectHandler = (emoji: any) => {
		const textarea: HTMLTextAreaElement | null = document.querySelector('.chat__textarea-item');
		if (
			textarea &&
			checkRealTextLength(textarea.value) + emoji.native.length < maxTextareaLength()
		) {
			if (textarea.selectionStart !== textarea.value.length) {
				const {selectionStart} = textarea;
				setTextareaValue(
					`${textarea.value.slice(0, selectionStart)}${emoji.native}${textarea.value.slice(
						textarea.selectionStart,
						textarea.value.length
					)}`
				);
				textarea.setSelectionRange(
					selectionStart + emoji.native.length,
					selectionStart + emoji.native.length
				);
			} else {
				setTextareaValue(`${textarea.value}${emoji.native}`);
				textarea.setSelectionRange(textarea.value.length, textarea.value.length);
			}
			textarea.blur();
			textarea.focus();
			setVisibleTextareaSendBtn(true);
		}
	};

	const renderControlsItem = useCallback(
		(btn: StickersEmojiControlsBtn) => {
			if (
				(stickers.length && btn.tabName === StickersEmojiType.STICKERS) ||
				btn.tabName === StickersEmojiType.EMOJI
			) {
				const findTitle = findValueByKeyInObject<string>(btn.title, language, btn.title.ru);
				return (
					<div className='stickers-emoji-viewer__controls-item' key={btn.id}>
						<Button
							className={`stickers-emoji-viewer__controls-button stickers-emoji-viewer__controls-button--${
								btn.tabName
							} ${btn.tabName === activeBtn ? 'active' : ''}`}
							onClick={() => setActiveBtn(btn.tabName)}>
							{btn.icon}
							{findTitle}
						</Button>
					</div>
				);
			}
			return null;
		},
		[activeBtn, stickers]
	);

	const renderStickersRmojiItems = useCallback(() => {
		switch (activeBtn) {
			case StickersEmojiType.STICKERS: {
				if (stickers.length) {
					return (
						<div className='stickers-emoji-viewer__item stickers-emoji-viewer__item--stickers'>
							<div className='stickers-emoji-viewer__item-axis-y'>
								<StickersPicker />
							</div>
						</div>
					);
				}
				return null;
			}
			case StickersEmojiType.EMOJI: {
				return (
					<div className='stickers-emoji-viewer__item stickers-emoji-viewer__item--emoji'>
						<Picker
							data={data}
							className='emoji-picker'
							theme='light'
							locale={language === LanguageTag.en ? 'en' : 'ru'}
							navPosition='bottom'
							searchPosition='none'
							previewPosition='none'
							dynamicWidth
							onEmojiSelect={onEmojiSelectHandler}
						/>
					</div>
				);
			}
			default: {
				return null;
			}
		}
	}, [activeBtn, stickers]);

	useEffect(() => {
		if (myTalker) {
			myTalkerRef.current = myTalker;
		}
	}, [myTalker]);

	useEffect(() => {
		if (stickers.length) {
			setActiveBtn(StickersEmojiType.STICKERS);
			return;
		}
		setActiveBtn(StickersEmojiType.EMOJI);
	}, [stickers.length]);

	useEffect(() => {
		if (isModalOpen) {
			togglleVisibleStickersEmoji(false);
		}
	}, [isModalOpen]);

	if (!visibleStickersEmoji) {
		return null;
	}

	return (
		<div className='stickers-emoji-viewer'>
			<Button
				className='stickers-emoji-viewer__close'
				onClick={onClickStickersEmojiViewerHandler}
			/>
			<div className='stickers-emoji-viewer__container'>
				<div className='stickers-emoji-viewer__controls'>
					{stickersEmojiBtnsData.map(renderControlsItem)}
				</div>
				<div className='stickers-emoji-viewer__items'>{renderStickersRmojiItems()}</div>
			</div>
		</div>
	);
};

export default observer(StickersEmojiViewer);
