import TooltipType from 'models/enums/TooltipType.enum';
import TooltipPositoinType from 'models/enums/TooltipPositoinType.enum';
import windowLocalStorage from 'utils/windowLocalStorage';
import useL10n from 'l10n/useL10n';
import appService from 'store/appService';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import controlPanelServices from 'store/controlPanelServices';
import modalServices from 'store/modalServices';
import tooltipsServices from 'store/tooltipsServices';
import {FunctionComponent, useEffect, useState, useRef} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {CSSTransition} from 'react-transition-group';
import {ReactComponent as IcoHand} from 'assets/svg/ico-hand.svg';
import {ReactComponent as IcoHandYellow} from 'assets/svg/ico-hand-yellow.svg';
import {ReactComponent as IcoShare} from 'assets/svg/ico-share.svg';
import Button from 'components/hoc/Button';
import Tooltip from 'components/tooltip/Tooltip';
import useAnalytics from 'hooks/useAnalytics';

const TRANSITION_DURATION_BUTTONS = 500;

const ControlPanelLeft: FunctionComponent = function ControlPanelLeft() {
	const referenceWaitingSpeakers = useRef<HTMLDivElement>(null);
	const referenceShareBet = useRef<HTMLDivElement>(null);
	const [visibleTooltip, setVisibleTooltip] = useState(false);
	const [visibleShareBetTooltip, setVisibleShareBetTooltip] = useState(false);
	const {appReadOnly, appVoice} = useLocalObservable(() => appService);
	const {isUserExternalIdCorrect} = useLocalObservable(() => userServices);
	const {talkersWaitingSpeakers, myTalker} = useLocalObservable(() => roomServices);
	const {visibleButtons} = useLocalObservable(() => controlPanelServices);
	const {toggleWaitingSpeakersModal, toggleShareBetModal} = useLocalObservable(() => modalServices);
	const {tooltip, toggleTooltip} = useLocalObservable(() => tooltipsServices);
	const {sendAnalytics} = useAnalytics();

	const {tooltips} = useL10n();

	const watcherswebTooltips = windowLocalStorage.get<Tooltips>('watcherswebTooltips');

	const myTalkerIsModer = !!myTalker?.isModer;
	const haveTalkersWaitingSpeakers = talkersWaitingSpeakers.length > 0;

	const onToggleWaitingSpeakersHandler = () => {
		toggleWaitingSpeakersModal(true);
	};

	const onToggleShareBetHandler = () => {
		if (!appReadOnly && isUserExternalIdCorrect) {
			sendAnalytics('Chat_SharingBet_Open');
			toggleShareBetModal(true);
		}
	};

	useEffect(() => {
		if (
			myTalker?.isModer &&
			haveTalkersWaitingSpeakers &&
			(!tooltip ||
				!tooltip.shown ||
				(tooltip && tooltip.shown && tooltip.type === TooltipType.INFORMATIONAL)) &&
			(!watcherswebTooltips ||
				(watcherswebTooltips && typeof watcherswebTooltips.waitingSpeakers === 'undefined'))
		) {
			setVisibleTooltip(true);
			windowLocalStorage.set('watcherswebTooltips', {
				...watcherswebTooltips,
				waitingSpeakers: false,
			});
			if (!tooltip?.shown) {
				toggleTooltip({shown: true, type: TooltipType.TRIGGER});
			}
		}
	}, [haveTalkersWaitingSpeakers, tooltip]);

	useEffect(() => {
		if (
			!myTalker?.isModer &&
			(!tooltip ||
				!tooltip.shown ||
				(tooltip && tooltip.shown && tooltip.type === TooltipType.INFORMATIONAL)) &&
			(!watcherswebTooltips ||
				(watcherswebTooltips && typeof watcherswebTooltips.shareBet === 'undefined'))
		) {
			setVisibleShareBetTooltip(true);
			windowLocalStorage.set('watcherswebTooltips', {
				...watcherswebTooltips,
				shareBet: false,
			});
			if (!tooltip?.shown) {
				toggleTooltip({shown: true, type: TooltipType.TRIGGER});
			}
		}
	}, [tooltip]);

	return (
		<CSSTransition in={visibleButtons} timeout={TRANSITION_DURATION_BUTTONS} classNames='fade'>
			<div className='control-panel__left'>
				{!myTalker?.isBanned && (
					<div ref={referenceShareBet}>
						{!myTalkerIsModer && (
							<>
								<Button className='control-panel__btn' onClick={onToggleShareBetHandler}>
									<IcoShare />
								</Button>

								{visibleShareBetTooltip && (
									<Tooltip
										referenceElement={referenceShareBet.current}
										position={TooltipPositoinType.TOP}
										text={tooltips.shareBet}
										delay={3000}
										callbackOnHide={() => {
											setVisibleShareBetTooltip(false);
											if (tooltip && tooltip.shown && tooltip.type === TooltipType.TRIGGER) {
												toggleTooltip(null);
											}
										}}
									/>
								)}
							</>
						)}
					</div>
				)}

				<div ref={referenceWaitingSpeakers}>
					{myTalkerIsModer && appVoice && (
						<>
							<Button className='control-panel__btn' onClick={onToggleWaitingSpeakersHandler}>
								{haveTalkersWaitingSpeakers ? <IcoHandYellow /> : <IcoHand />}
							</Button>

							{visibleTooltip && (
								<Tooltip
									referenceElement={referenceWaitingSpeakers.current}
									position={TooltipPositoinType.TOP}
									text={tooltips.waitingSpeakers}
									delay={3000}
									callbackOnHide={() => {
										setVisibleTooltip(false);
										if (tooltip && tooltip.shown && tooltip.type === TooltipType.TRIGGER) {
											toggleTooltip(null);
										}
									}}
								/>
							)}
						</>
					)}
				</div>
			</div>
		</CSSTransition>
	);
};

export default observer(ControlPanelLeft);
