import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import React, {useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {useHistory} from 'react-router-dom';
import userServices from 'store/userServices';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import UserService from 'services/api/UserService';
import alertService from 'store/alertService';
import {AlertBtnType} from 'models/enums/Alert.enum';
import Button from 'components/hoc/Button';
import appService from 'store/appService';

import './restore.scss';

const DAYS_BEFORE_DELETE = 30;

const Restore: React.FC = function Restore() {
	const history = useHistory();
	const translations = useL10n();
	const {sendAnalytics} = useAnalytics();
	const {userData, accessToken} = useLocalObservable(() => userServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);
	const {language} = useLocalObservable(() => appService);
	const [deletedDate, setDeletedDate] = useState(new Date());
	const locale = ['ru-Ru', 'en-GB', 'am-Am'].find(item => item.includes(language)) || 'en-GB';

	useEffect(() => {
		if (userData?.deletedAt) {
			const localDate = new Date(userData?.deletedAt);
			userData?.deletedAt &&
				localDate.setDate(new Date(userData?.deletedAt).getDate() + DAYS_BEFORE_DELETE);
			setDeletedDate(localDate);
		}
	}, [userData]);

	const restoreUserHandler = async () => {
		if (accessToken) {
			const response = await UserService.restoreMyUser(accessToken);
			if (response.status === ResponseStatus.SUCCESS) {
				sendAnalytics('Social_ButtonClick_AccountRestored');
				history.push('/');
				showAlert({
					title: translations.alerts.accountRestored.title,
					buttons: [
						{
							text: translations.alerts.btns.ok,
							type: AlertBtnType.NORMAL,
							onPress: hideAlert,
						},
					],
					closeOnBackdropPress: true,
				});
			}
		}
	};

	return (
		<div className='restore'>
			<p className='restore__title'>{translations.accountDeletedScreen.title}</p>
			<p className='restore__text'>
				{translations.accountDeletedScreen.text}{' '}
				{deletedDate.toLocaleString(locale, {
					hour: 'numeric',
					minute: 'numeric',
				})}
				{`, `}
				{deletedDate.toLocaleDateString(locale, {
					year: 'numeric',
					month: 'long',
					day: 'numeric',
				})}
			</p>
			<div className='restore__wrapper'>
				{/* <p className='restore__watchers'>{translations.coopyrights}</p> */}
				<Button onClick={restoreUserHandler} className='btn restore__footer-btn'>
					{translations.btns.restoreAccount}
				</Button>
			</div>
		</div>
	);
};

export default observer(Restore);
