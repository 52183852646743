import {Message} from 'models/room';
import reportModalService from 'store/reportModalService';
import roomServices from 'store/roomServices';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Button from 'components/hoc/Button';
import {ReactComponent as ReportIconMessage} from 'assets/svg/ico-report-message.svg';
import {ReactComponent as ReportIconUser} from 'assets/svg/ico-report-user.svg';

interface IButtonReportProps {
	message: Message;
}

const ButtonReport: FunctionComponent<IButtonReportProps> = function ButtonReport({message}) {
	const {chatScreen} = useL10n();
	const {id: messageId, talker} = message;
	const {setSubmenuMessage} = useLocalObservable(() => roomServices);
	const {showReportModal} = useLocalObservable(() => reportModalService);
	const {sendAnalytics} = useAnalytics();

	const onReportHandler = (target: 'message' | 'user') => {
		sendAnalytics('Social_ButtonClick_Complain');
		showReportModal({
			report: {
				talker,
				target,
				messageId,
				blockUserByUserAvailable: true,
			},
		});
		setSubmenuMessage(null);
	};

	return (
		<>
			<Button className='chat__submenu-btn' onClick={() => onReportHandler('message')}>
				{chatScreen.chatSubmenu.reportMessage}
				<ReportIconMessage className='chat__submenu-icon' />
			</Button>

			<Button className='chat__submenu-btn' onClick={() => onReportHandler('user')}>
				{chatScreen.chatSubmenu.reportUser}
				<ReportIconUser className='chat__submenu-icon' />
			</Button>
		</>
	);
};

export default observer(ButtonReport);
