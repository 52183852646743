enum BetTypes {
	SINGLE = 'SINGLE',
	EXPRESS = 'EXPRESS',
}

enum BetStatus {
	WIN = 'WIN',
	LOSE = 'LOSE',
}

export {BetTypes, BetStatus};
