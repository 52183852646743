import {times} from 'data/slowModeData';
import {containsClass} from 'utils/helpers';
import {findValueByKeyInObject} from 'utils/findValueByKeyInObject';
import MessageType from 'models/enums/MessageType.enum';
import ToasNames from 'models/enums/ToasNames.enum';
import {Sticker} from 'models/room';
import appService from 'store/appService';
import roomServices from 'store/roomServices';
import stickersEmojiServices from 'store/stickersEmojiServices';
import slowModeServices from 'store/slowModeServices';
import toastService from 'store/toastService';
import SocketIoServices from 'services/SocketIoServices';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect} from 'react';
import throttle from 'lodash/throttle';
import {ToastIconName} from 'components/toasts/ToastIcons';
import './stickers-picker.scss';

const StickersPicker: FunctionComponent = function StickersPicker() {
	const {language} = useLocalObservable(() => appService);
	const {roomId, myTalker} = useLocalObservable(() => roomServices);
	const {togglleVisibleStickersEmoji, stickers} = useLocalObservable(() => stickersEmojiServices);
	const {slowMode, slowModeCountdownEnable, setSlowModeTimeLeft, toggleSlowModeCountdownEnable} =
		useLocalObservable(() => slowModeServices);
	const {toasts, addToast} = useLocalObservable(() => toastService);
	const {sendAnalytics} = useAnalytics();

	const {toasts: toastsLanguage} = useL10n();

	const isMyTalkerModer = !!myTalker?.isModer;

	const onClickStickerHandler = throttle(
		(event: any) => {
			const eventTarget = event.target;
			if (eventTarget && containsClass(eventTarget, 'stickers-picker__item') && roomId) {
				event.preventDefault();
				const stickerId = parseInt(eventTarget.dataset.stickerId, 10);
				if (
					(stickerId && !slowMode.global.enable && !slowMode.local.enable) ||
					(stickerId &&
						(slowMode.global.enable || slowMode.local.enable) &&
						!slowModeCountdownEnable)
				) {
					SocketIoServices.emitMessage({
						externalRoomId: roomId,
						stickerId,
						type: MessageType.STICKER,
					});

					if (!isMyTalkerModer && (slowMode.global.enable || slowMode.local.enable)) {
						setSlowModeTimeLeft(slowMode.local.enable ? slowMode.local.time : slowMode.global.time);
						toggleSlowModeCountdownEnable(true);
					}

					togglleVisibleStickersEmoji(false);
					sendAnalytics('Chat_Sticker_Sent', {
						sticker_number: stickers.map(sticker => sticker.id).indexOf(stickerId) + 1,
					});
					return;
				}

				if ((slowMode.global.enable || slowMode.local.enable) && slowModeCountdownEnable) {
					let findtTime = times.find(item => item.time === slowMode.local.time);
					if (slowMode.global.enable && !slowMode.local.enable) {
						findtTime = times.find(item => item.time === slowMode.global.time);
					}
					if (findtTime && !toasts.find(t => t.name && t.name === ToasNames.SENDSTICKER)) {
						const findTitle = findValueByKeyInObject<{default: string; prefix?: string}>(
							findtTime.title,
							language,
							{default: findtTime.title.ru.default}
						);
						addToast({
							name: ToasNames.SENDSTICKER,
							iconName: ToastIconName.warning,
							liveTime: 2000,
							message: toastsLanguage.sendStickerWhenSlowModeIsOn(findTitle.default),
							cancellable: true,
						});
					}
				}
			}
		},
		250,
		{leading: true, trailing: false}
	);

	const renderStickerItem = (item: Sticker) => (
		<div
			className='stickers-picker__item'
			key={item.id}
			data-sticker-id={item.id}
			style={{backgroundImage: `url('${item.pic}')`}}
		/>
	);

	useEffect(() => {
		document.addEventListener('click', onClickStickerHandler);
		return () => {
			document.removeEventListener('click', onClickStickerHandler);
		};
	}, [roomId, slowMode, slowModeCountdownEnable, toasts]);

	return (
		<div className='stickers-picker'>
			<div className='stickers-picker__items'>{stickers.map(renderStickerItem)}</div>
		</div>
	);
};

export default observer(StickersPicker);
