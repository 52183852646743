import AudioFeedbackSteps from 'models/enums/AudioFeedbackSteps';
import useFeedbackAudio from 'hooks/useFeedbackAudio';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import feedbackServices from 'store/feedbackServices';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {AudioQuality} from './audioQuality';
import {AudioReason} from './audioReason';
import {AudioConnection} from './audioConnection';
import {AudioStatistics} from './audioStatistics';
import {AudioDone} from './audioDone';
import './audio.scss';

const audioFeedbackSteps = [
	AudioFeedbackSteps.QUALITY,
	AudioFeedbackSteps.REASON,
	AudioFeedbackSteps.CONNECTION,
	AudioFeedbackSteps.STATISTICS,
	AudioFeedbackSteps.DONE,
];

const Audio: FunctionComponent = function Audio() {
	const {audioFeedbackActiveStep} = useLocalObservable(() => feedbackServices);
	const {sendAudioFeedbackAndExitApp} = useFeedbackAudio();
	const {feedback} = useL10n();
	const {sendAnalytics} = useAnalytics();

	const onCloseAudioModalHandler = () => {
		sendAnalytics('Chat_Stream_Closed', {
			step: audioFeedbackSteps.indexOf(audioFeedbackActiveStep) + 1,
		});
		sendAudioFeedbackAndExitApp();
	};

	const renderAudioFeedbackStep = () => {
		switch (audioFeedbackActiveStep) {
			case AudioFeedbackSteps.QUALITY:
				return <AudioQuality />;
			case AudioFeedbackSteps.REASON:
				return <AudioReason />;
			case AudioFeedbackSteps.CONNECTION:
				return <AudioConnection />;
			case AudioFeedbackSteps.STATISTICS:
				return <AudioStatistics />;
			case AudioFeedbackSteps.DONE:
				return <AudioDone />;
			default:
				return null;
		}
	};

	return (
		<div className='audio'>
			{renderAudioFeedbackStep()}
			<button
				type='button'
				className='audio__btn audio__btn--accent'
				onClick={onCloseAudioModalHandler}>
				{feedback.audio.btns.close}
			</button>
		</div>
	);
};

export default observer(Audio);
