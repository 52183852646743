import {findValueByKeyInObject} from 'utils/findValueByKeyInObject';
import AudioFeedbackSteps from 'models/enums/AudioFeedbackSteps';
import AudioReasons from 'models/enums/AudioReasons';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import appService from 'store/appService';
import feedbackServices from 'store/feedbackServices';
import {FunctionComponent, useCallback, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {v4 as uuidv4} from 'uuid';
import {Checkbox} from 'components/ui';

type AudioReasonItem = {
	reason: AudioReasons;
	text: {
		ru: string;
		en: string;
	};
	event: string;
};

const audioReasonItems: AudioReasonItem[] = [
	{
		reason: AudioReasons.NOTHEAR,
		text: {
			ru: 'Ничего не было слышно',
			en: "I didn't hear anything",
		},
		event: 'dn_work',
	},
	{
		reason: AudioReasons.CONNECTION,
		text: {
			ru: 'Звук прерывался',
			en: 'The sound was choppy',
		},
		event: 'interruptions',
	},
	{
		reason: AudioReasons.MICROPHONE,
		text: {
			ru: 'Не получилось воспользоваться микрофоном',
			en: "Microphone didn't work",
		},
		event: 'microphone',
	},
];

const AudioReason: FunctionComponent = function AudioReason() {
	const [checkedReasons, setCheckedReasons] = useState<string[]>([]);
	const [checkedReasonsEvents, setCheckedReasonsEvents] = useState<string[]>([]);

	const {language} = useLocalObservable(() => appService);
	const {setAudioFeedbackData, toggleAudioFeedbackActiveStep} = useLocalObservable(
		() => feedbackServices
	);

	const {feedback} = useL10n();
	const {sendAnalytics} = useAnalytics();

	const onContinueHandler = () => {
		setAudioFeedbackData({issue: checkedReasons.join(', ')});
		sendAnalytics('Chat_Stream_TypeOfProblem', {type: checkedReasonsEvents.join(', ')});
		toggleAudioFeedbackActiveStep(AudioFeedbackSteps.CONNECTION);
	};

	const onAudioReasonCheckboxHandler = ({reason, event}: AudioReasonItem) => {
		if (checkedReasons.includes(reason)) {
			setCheckedReasons(prevState => prevState.filter(r => r !== reason));
			setCheckedReasonsEvents(prevState => prevState.filter(e => e !== event));
			return;
		}
		setCheckedReasons(prevState => [...prevState, reason].sort());
		setCheckedReasonsEvents(prevState => [...prevState, event].sort());
	};

	const renderAudioReasonItem = useCallback(
		(item: AudioReasonItem) => {
			const findText = findValueByKeyInObject(item.text, language, item.text.ru);
			return (
				<div className='audio__reason-item' key={uuidv4()}>
					<Checkbox
						text={findText}
						checked={checkedReasons.includes(item.reason)}
						onChange={() => onAudioReasonCheckboxHandler(item)}
					/>
				</div>
			);
		},
		[checkedReasons, language]
	);

	return (
		<div className='audio__reason'>
			<div className='audio__title'>{feedback.audio.reason.title}</div>
			<div className='audio__reason-items'>{audioReasonItems.map(renderAudioReasonItem)}</div>
			<button
				type='button'
				className='audio__btn audio__btn--accent'
				disabled={!checkedReasons.length}
				onClick={onContinueHandler}>
				{feedback.audio.btns.continue}
			</button>
		</div>
	);
};

export default observer(AudioReason);
