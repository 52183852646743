/* eslint-disable max-lines */
import {MODAL_STYLE} from 'constants/constants';
import {AlertBtnType} from 'models/enums/Alert.enum';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import ReportReasons from 'models/enums/ReportReasons.enum';
import {BanReason} from 'models/enums/BanModerReasons.enum';
import appService from 'store/appService';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import reportModalService from 'store/reportModalService';
import alertService from 'store/alertService';
import toastService from 'store/toastService';
import RoomService from 'services/api/RoomService';
import {encryptionUserName} from 'utils/encryptionUserName';
import {FunctionComponent, useCallback} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import {ToastIconName} from 'components/toasts/ToastIcons';
import {ReactComponent as IcoReport} from 'assets/svg/ico-report.svg';
import {ReactComponent as IcoBan} from 'assets/svg/ico-ban.svg';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import SocketIoServices from 'services/SocketIoServices';
import './reportModal.scss';
import Button from 'components/hoc/Button';

const ReportModal: FunctionComponent = function ReportModal() {
	const {language} = useLocalObservable(() => appService);
	const {accessToken} = useLocalObservable(() => userServices);
	const {roomId, removeMentionMessageForBlockedUser} = useLocalObservable(() => roomServices);
	const {isVisible, reportModalProps, hideReportModal} = useLocalObservable(
		() => reportModalService
	);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);
	const {addToast} = useLocalObservable(() => toastService);
	const {alerts, modals, toasts} = useL10n();
	const {sendAnalytics} = useAnalytics();

	const reportModalType = !reportModalProps?.ban;
	const reportModalTarget = reportModalProps?.report?.target || 'message';
	const reportModalMessageId = reportModalProps?.report?.messageId;
	const reportModalTalker = reportModalProps?.report?.talker;
	const reportModalblockUserByUserAvailable = reportModalProps?.report?.blockUserByUserAvailable;

	const reportButtons = [
		{
			text: modals.report.btns.violence,
			type: ReportReasons.VIOLENCE,
			gaEvent: 'report_pressed',
			gaEventType: 'violence',
		},
		{
			text: modals.report.btns.provocations,
			type: ReportReasons.PROVOCATIONS,
			gaEvent: 'report_pressed',
			gaEventType: 'provocations',
		},
		{
			text: modals.report.btns.personalDetails,
			type: ReportReasons.PERSONALDETAILS,
			gaEvent: 'report_pressed',
			gaEventType: 'personal_details',
		},
		{
			text: modals.report.btns.flood,
			type: ReportReasons.FLOOD,
			gaEvent: 'report_pressed',
			gaEventType: 'flood',
		},
		{
			text: modals.report.btns.spam,
			type: ReportReasons.SPAM,
			gaEvent: 'report_pressed',
			gaEventType: 'spam',
		},
		{
			text: modals.report.btns.fraudBegging,
			type: ReportReasons.FRAUDBEGGING,
			gaEvent: 'report_pressed',
			gaEventType: 'fraud/begging',
		},
		{
			text: modals.report.btns.other,
			type: ReportReasons.OTHER,
			gaEvent: 'report_pressed',
			gaEventType: 'other',
		},
	];

	const blockButtons = [
		{
			text: modals.ban.btns.insults,
			type: BanReason.INSULTS,
			gaEvent: 'insults_banreason_pressed',
			gaEventConfirmed: 'insults_banreason_confirmed',
			gaEventCancelled: 'insults_banreason_cancelled',
		},
		{
			text: modals.ban.btns.personal,
			type: BanReason.PERSONAL,
			gaEvent: 'personal_banreason_pressed',
			gaEventConfirmed: 'personal_banreason_confirmed',
			gaEventCancelled: 'personal_banreason_cancelled',
		},
		{
			text: modals.ban.btns.flood,
			type: BanReason.FLOOD,
			gaEvent: 'flood_banreason_pressed',
			gaEventConfirmed: 'flood_banreason_confirmed',
			gaEventCancelled: 'flood_banreason_cancelled',
		},
		{
			text: modals.ban.btns.links,
			type: BanReason.LINKS,
			gaEvent: 'links_banreason_pressed',
			gaEventConfirmed: 'links_banreason_confirmed',
			gaEventCancelled: 'links_banreason_cancelled',
		},
		{
			text: modals.ban.btns.violence,
			type: BanReason.VIOLENCE,
			gaEvent: 'violence_banreason_pressed',
			gaEventConfirmed: 'violence_banreason_confirmed',
			gaEventCancelled: 'violence_banreason_cancelled',
		},
		{
			text: modals.ban.btns.fraud,
			type: BanReason.FRAUD,
			gaEvent: 'fraud_banreason_pressed',
			gaEventConfirmed: 'fraud_banreason_confirmed',
			gaEventCancelled: 'fraud_banreason_cancelled',
		},
		{
			text: modals.ban.btns.nickname,
			type: BanReason.NICKNAME,
			gaEvent: 'nickname_banreason_pressed',
			gaEventConfirmed: 'nickname_banreason_confirmed',
			gaEventCancelled: 'nickname_banreason_cancelled',
		},
		{
			text: modals.ban.btns.politicial,
			type: BanReason.POLITICAL,
			gaEvent: 'politicial_banreason_pressed',
			gaEventConfirmed: 'politicial_banreason_confirmed',
			gaEventCancelled: 'politicial_banreason_cancelled',
		},
		{
			text: modals.ban.btns.repeated,
			type: BanReason.REPEATED,
			gaEvent: 'repeated_banreason_pressed',
			gaEventConfirmed: 'repeated_banreason_confirmed',
			gaEventCancelled: 'repeated_banreason_cancelled',
		},
		{
			text: modals.ban.btns.spam,
			type: BanReason.SPAM,
			gaEvent: 'spam_banreason_pressed',
			gaEventConfirmed: 'spam_banreason_confirmed',
			gaEventCancelled: 'spam_banreason_cancelled',
		},
	];

	const onSentReportHandler = async (reason: string, blockUser?: boolean) => {
		if (blockUser && reportModalTalker?.user.id) {
			await RoomService.blockUser(reportModalTalker.user.id, accessToken);
		}
		if (reportModalMessageId) {
			const response = await RoomService.reportUser(reportModalMessageId, reason, accessToken);
			if (response.status === ResponseStatus.SUCCESS) {
				addToast({
					iconName: ToastIconName.warning,
					liveTime: 3000,
					message: toasts.reciveComplaint,
					cancellable: true,
				});
			}
		}
	};

	const onPressBanHandler = async (reason: number) => {
		if (reportModalProps?.ban && roomId) {
			if (SocketIoServices.socket !== null) {
				SocketIoServices.setBan(reportModalProps.ban.userId, roomId, true, reason);
			}
			// фикс количества реплайнутых сообщений
			removeMentionMessageForBlockedUser(reportModalProps.ban.userId);
		}
	};

	const onClickReportHandler = (item: {
		text: string;
		type: any;
		gaEvent: string;
		gaEventType: string;
		gaEventConfirmed: string;
		gaEventCancelled: string;
	}) => {
		let alertProps: any = {
			title: alerts.report.title,
			subtitle: alerts.report.subtitle,
			buttons: [
				{
					text: alerts.btns.send,
					type: AlertBtnType.NORMAL,
					onPress: () => {
						onSentReportHandler(item.type);
						sendAnalytics('Social_ButtonClick_ComplainConfirm', {complain_type: item.gaEventType});
						hideAlert();
					},
				},
				{
					text: alerts.btns.sendAndBlock,
					type: AlertBtnType.NORMAL,
					onPress: () => {
						onSentReportHandler(item.type, true);
						sendAnalytics('Social_ButtonClick_ComplainConfirm', {complain_type: item.gaEventType});
						hideAlert();
					},
				},
				{
					text: alerts.btns.cancel,
					type: AlertBtnType.DESTRUCTIVE,
					onPress: () => {
						sendAnalytics('Social_ButtonClick_ComplainCancel', {complain_type: item.gaEventType});
						hideAlert();
					},
				},
			],
			closeOnBackdropPress: true,
		};

		if (
			(typeof reportModalTalker !== 'undefined' && reportModalTalker.isModer) ||
			!reportModalblockUserByUserAvailable
		) {
			alertProps = {
				title: alerts.report.title,
				buttons: [
					{
						text: alerts.btns.send,
						type: AlertBtnType.NORMAL,
						onPress: () => {
							sendAnalytics('Social_ButtonClick_ComplainConfirm', {
								complain_type: item.gaEventType,
							});
							onSentReportHandler(item.type);
							hideAlert();
						},
					},
					{
						text: alerts.btns.cancel,
						type: AlertBtnType.DESTRUCTIVE,
						onPress: () => {
							sendAnalytics('Social_ButtonClick_ComplainCancel', {complain_type: item.gaEventType});
							hideAlert();
						},
					},
				],
				closeOnBackdropPress: true,
			};
		}

		sendAnalytics('Social_ButtonClick_Complain', item.type);
		hideReportModal();
		showAlert(alertProps);
	};

	const onClickBanHandler = (item: {
		text: string;
		type: any;
		gaEvent: string;
		gaEventConfirmed: string;
		gaEventCancelled: string;
	}) => {
		// sendAnalytics(item.gaEvent);
		hideReportModal();
		showAlert({
			title: reportModalProps?.ban?.name
				? alerts.banUser.title(encryptionUserName(reportModalProps.ban.name))
				: 'User',
			subtitle: alerts.banUser.subtitle(item.text.toLocaleLowerCase()),
			buttons: [
				{
					text: alerts.btns.block,
					type: AlertBtnType.NORMAL,
					onPress: () => {
						// sendAnalytics(item.gaEventConfirmed);
						onPressBanHandler(item.type);
						hideAlert();
					},
				},
				{
					text: alerts.btns.cancel,
					type: AlertBtnType.DESTRUCTIVE,
					onPress: () => {
						// sendAnalytics('Social_ButtonClick_ComplainCancel', item.type);
						hideAlert();
					},
				},
			],
			closeOnBackdropPress: true,
		});
	};

	const closeModalHandler = () => {
		hideReportModal();
		if (reportModalType) {
			sendAnalytics('Social_NavigationClick_ComplainClose');
		}
	};

	const renderReportButons = useCallback(
		(item, index) => (
			<Button
				className='report-modal__btn'
				key={index}
				onClick={() => (reportModalType ? onClickReportHandler(item) : onClickBanHandler(item))}>
				{item.text}
			</Button>
		),
		[reportModalProps, language]
	);

	return (
		<Modal
			className='report-modal'
			isOpen={isVisible}
			ariaHideApp={false}
			style={{
				overlay: {...MODAL_STYLE.overlay, zIndex: 1001},
			}}>
			<Button className='report-modal__close' onClick={closeModalHandler}>
				close
			</Button>
			<div className='report-modal__header'>
				<div className='report-modal__header-overicon'>
					{reportModalType ? (
						<IcoReport className='report-modal__header-icon' />
					) : (
						<IcoBan className='report-modal__header-icon' />
					)}
				</div>
				<div className='report-modal__header-texts'>
					<p className='report-modal__title'>
						{reportModalType ? modals.report.title(reportModalTarget) : modals.ban.title}
					</p>
					<p className='report-modal__subtitle'>
						{reportModalType ? modals.report.subtitle : modals.ban.subtitle}
					</p>
				</div>
			</div>
			<div className='report-modal__body'>
				<div className='report-modal__axis-y'>
					<div className='report-modal__btns-list'>
						{reportModalType
							? reportButtons.map(renderReportButons)
							: blockButtons.map(renderReportButons)}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default observer(ReportModal);
