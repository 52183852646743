import {useCallback, useEffect, useRef, useState} from 'react';

/* istanbul ignore next */

const copyToClipboard = (text: string) => {
	// uses the Async Clipboard API when available. Requires a secure browing
	// context (i.e. HTTPS)

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	if (typeof Copy !== 'undefined') {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		Copy.postMessage(text);
		return Promise.resolve();
	}

	if (navigator.clipboard && !navigator.userAgent.match(/opr\//i)) {
		return navigator.clipboard.writeText(text);
	}
	// puts the text to copy into a <span>
	const span = document.createElement('span');
	span.textContent = text;
	// preserves consecutive spaces and newlines
	span.style.whiteSpace = 'pre';
	// adds the <span> to the page
	document.body.appendChild(span);
	// makes a selection object representing the range of text selected by the user
	const selection = window.getSelection();
	if (!selection) {
		return Promise.reject();
	}
	const range = window.document.createRange();
	selection.removeAllRanges();
	range.selectNode(span);
	selection.addRange(range);
	// copies text to the clipboard
	try {
		window.document.execCommand('copy');
	} catch (err) {
		return Promise.reject();
	}
	// cleans up the dom element and selection
	selection.removeAllRanges();
	window.document.body.removeChild(span);
	// the Async Clipboard API returns a promise that may reject with `undefined`
	// so we match that here for consistency
	return Promise.resolve();
};

const useCopy = (text: string) => {
	const [copied, setCopied] = useState(false);
	const reset = useRef(() => setCopied(false));
	// Resets 'copied' if text changes
	useEffect(() => reset.current, [text]);

	return {
		copied,
		copy: useCallback(
			() =>
				copyToClipboard(text)
					.then(() => {
						setCopied(true);
					})
					.catch(e => {
						// eslint-disable-next-line no-alert
						alert(e);
						// eslint-disable-next-line @typescript-eslint/no-shadow
						setCopied(copied => copied);
					}),
			[text]
		),
		reset: reset.current,
	} as const;
};

export default useCopy;
