import windowLocalStorage from 'utils/windowLocalStorage';
import appService from 'store/appService';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import modalServices from 'store/modalServices';
import UserService from 'services/api/UserService';
import useBanUsers from 'hooks/useBanUsers';
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {observer, useLocalObservable} from 'mobx-react-lite';
import './settings.scss';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import {AlertBtnType} from 'models/enums/Alert.enum';
import alertService from 'store/alertService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import AgoraServices from 'services/api/AgoraService';
import Button from 'components/hoc/Button';
import {Select} from 'components/ui';
import UserRole from 'models/enums/UserRole.enum';

const Settings: React.FC = function Settings() {
	const history = useHistory();
	const {accessToken, updateUserData} = useLocalObservable(() => userServices);
	const {myTalker} = useLocalObservable(() => roomServices);
	const {
		appVoice,
		rules,
		blockedUsers,
		changeDeleteState,
		changeAgoraStatus,
		agoraMicrophoneActive,
		agoraMicrophones,
		agoraPlaybackDeviceActive,
		agoraPlaybackDevices,
		setAgoraMicrophoneActive,
		setAgoraPlaybackDeviceActive,
	} = useLocalObservable(() => appService);
	const {
		showProfileModal,
		showBlockedModal,
		toggleLanguageModal,
		toggleRulesModal,
		toggleSlowModeModal,
		hideAllModals,
	} = useLocalObservable(() => modalServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);

	const {getBanUsersWithServices} = useBanUsers();
	const {alerts, settingsScreen, coopyrights} = useL10n();
	const {sendAnalytics} = useAnalytics();

	const isMyTalkerModer = !!myTalker?.isModer;
	const isMyTalkerSpeacker = myTalker?.role === UserRole.SPEAKER;

	const agoraDataForSelect = (data: MediaDeviceInfo[]) => {
		const dataFilterFromLabel = data.filter(item => item.label !== '');
		if (dataFilterFromLabel.length) {
			return dataFilterFromLabel.map(item => {
				let cleanTitle = item.label;
				const cleanTitleSplit = cleanTitle.split(' ');
				if (
					cleanTitleSplit.length > 1 &&
					cleanTitleSplit[cleanTitleSplit.length - 1].includes('(')
				) {
					cleanTitle = cleanTitleSplit.slice(0, -1).join(' ');
				}
				return {
					id: item.deviceId,
					title: cleanTitle,
				};
			});
		}
		return [];
	};

	const onRemoveUserHandler = () => {
		sendAnalytics('Social_ButtonClick_ProfileDelete');
		showAlert({
			title: alerts.deleteAccount.title,
			subtitle: alerts.deleteAccount.subTitle,
			buttons: [
				{
					text: alerts.btns.deleteAccount,
					type: AlertBtnType.DESTRUCTIVE,
					onPress: async () => {
						const response = await UserService.selfDeleteUser(accessToken);
						if (response.status === ResponseStatus.SUCCESS) {
							updateUserData({deletedAt: `${new Date()}`});
							changeDeleteState(true);
							hideAllModals();
							hideAlert();
							sendAnalytics('Social_ButtonClick_ProfileDeleteConfirmed');
							AgoraServices.destroy(changeAgoraStatus);
							// setTimeout(() => history.push(url), 100);
							setTimeout(() => history.push('/restore'), 100);
						}
					},
				},
				{
					text: alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onPress: () => {
						sendAnalytics('Social_ButtonClick_ProfileDeleteCancelled');
						hideAlert();
					},
				},
			],
			closeOnBackdropPress: true,
		});
	};

	const onClickProfileBtnHandler = () => {
		sendAnalytics('Social_ButtonClick_Profile');
		showProfileModal();
	};

	const onClickBannedBtnHandler = () => {
		sendAnalytics('Social_ButtonClick_Banned');
		showBlockedModal();
	};

	const onClickSlowModeBtnHandler = () => {
		toggleSlowModeModal(true);
	};

	const onChangeAgoraMicrophoneDevice = (option: SelectOption) => {
		AgoraServices.setMicrophone(option.id);
		const findMicrophone = agoraMicrophones.find(microphone => microphone.deviceId === option.id);
		if (findMicrophone) {
			setAgoraMicrophoneActive(findMicrophone);
			windowLocalStorage.set('watcherswebAgoraMicrophone', findMicrophone);
		}
	};

	const onChangeAgoraPlaybackDevice = (option: SelectOption) => {
		AgoraServices.setPlaybackDevice(option.id);
		const findPlaybackDevice = agoraPlaybackDevices.find(
			playbackDevice => playbackDevice.deviceId === option.id
		);
		if (findPlaybackDevice) {
			setAgoraPlaybackDeviceActive(findPlaybackDevice);
			windowLocalStorage.set('watcherswebAgoraPlaybackDevice', findPlaybackDevice);
		}
	};

	useEffect(() => {
		getBanUsersWithServices();
	}, []);

	return (
		<div className='settings'>
			<div className='settings__groups'>
				{(isMyTalkerModer || isMyTalkerSpeacker) && appVoice && agoraMicrophones.length > 1 && (
					<div className='settings__group'>
						<div className='settings__group-title'>{settingsScreen.agora.microphone}</div>
						<div className='settings__items'>
							<div className='settings__item'>
								<Select
									options={agoraDataForSelect(agoraMicrophones)}
									optionIdActive={agoraMicrophoneActive?.deviceId}
									onOptionHandler={onChangeAgoraMicrophoneDevice}
								/>
							</div>
						</div>
					</div>
				)}

				{appVoice && agoraPlaybackDevices.length > 1 && (
					<div className='settings__group'>
						<div className='settings__group-title'>{settingsScreen.agora.playbackDevice}</div>
						<div className='settings__items'>
							<div className='settings__item'>
								<Select
									options={agoraDataForSelect(agoraPlaybackDevices)}
									optionIdActive={agoraPlaybackDeviceActive?.deviceId}
									onOptionHandler={onChangeAgoraPlaybackDevice}
								/>
							</div>
						</div>
					</div>
				)}

				<div className='settings__group'>
					<div className='settings__group-title'>{settingsScreen.personal}</div>
					<div className='settings__items'>
						<div className='settings__item'>
							<Button className='settings__button' onClick={onClickProfileBtnHandler}>
								{settingsScreen.profileBtn}
							</Button>
						</div>
						{/* <div className='settings__item'>
								<button
									type='button'
									className='settings__button'
									onClick={() => toggleLanguageModal(true)}>
									{settingsScreen.languageBtn}
								</button>
							</div>
						*/}
						<div className='settings__item'>
							<Button
								className='settings__button'
								onClick={onClickBannedBtnHandler}
								disabled={!blockedUsers?.length}>
								{settingsScreen.blockedBtn}
								{blockedUsers?.length > 0 && <span>{blockedUsers.length}</span>}
							</Button>
						</div>
					</div>
				</div>

				{isMyTalkerModer && (
					<div className='settings__group'>
						<div className='settings__group-title'>{settingsScreen.room}</div>
						<div className='settings__items'>
							<div className='settings__item'>
								<Button className='settings__button' onClick={onClickSlowModeBtnHandler}>
									{settingsScreen.slowModeBtn}
								</Button>
							</div>
						</div>
					</div>
				)}
			</div>

			<div className='settings__bottom'>
				{rules && (
					<Button onClick={() => toggleRulesModal(true)} className='settings__delete-account'>
						{settingsScreen.rules}
					</Button>
				)}

				<Button onClick={onRemoveUserHandler} className='settings__delete-account'>
					{settingsScreen.deleteProfile}
				</Button>
				{/* <span className='settings__powered-by'>{coopyrights}</span> */}
			</div>
		</div>
	);
};

export default observer(Settings);
