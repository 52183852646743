/* eslint-disable max-lines */
import UserRole from 'models/enums/UserRole.enum';
import AnonimAva from 'assets/images/anonim-ava.jpg';

export const MODAL_STYLE = {
	overlay: {
		display: 'flex',
		alignItems: 'flex-end',
		backgroundColor: 'rgba(0, 0, 0, 0)',
		zIndex: 1000,
	},
};

export const USER_UNNAMED = 'Имя';
export const LINK_PREVIEW_PROXY = 'https://proxy.watchers.io/?url=';
export const MINIMUM_NUMBER_USERS_TO_HIDE = process.env.REACT_APP_PROJECT === 'liga-prod' ? 45 : 2;
export const MINIMUM_NUMBER_USERS_TO_SHOW = process.env.REACT_APP_PROJECT === 'liga-prod' ? 55 : 4;

// for yandex cloud
export const IMAGE_FOR_SPEEDTEST = 'https://storage.yandexcloud.net/watchers/test.jpg';

// for digitalocean
// export const IMAGE_FOR_SPEEDTEST = 'https://watchers.fra1.digitaloceanspaces.com/test.jpg';

export const URL_FOR_TECHNICAL_ANALYTICS_DEV = 'https://webbackend-statistics.dev.watchers.io';
export const URL_FOR_TECHNICAL_ANALYTICS_PROD = 'https://webbackend-statistics.watchers.io';

export const USER_STUB = {
	id: 5146834951386002,
	pic: AnonimAva,
	name: 'Watchers user002',
	color: 0,
	email: '',
	isModer: false,
	isBanned: false,
};

export const TALKER_STUB = {
	id: 5146834951386001,
	hand: false,
	role: UserRole.GUEST,
	user: USER_STUB,
	isModer: true,
	isMuted: true,
	isActive: false,
	isBanned: false,
	isSupressed: false,
};

export const START_NUMBER_OF_MESSAGES = 40;
export const MAXIMUM_NUMBER_OF_MESSAGES = 60;
export const NUMBER_OF_MESSAGES_TO_DELETE = 20;
export const NUMBER_OF_MESSAGES_TO_DOWNLOAD = 20;

export const MAX_TEXTAREA_LENGTH = 500;
export const MAX_TEXTAREA_LENGTH_FOR_MODERATOR = 1500;
export const MIN_TEXTAREA_LENGTH_VISIBLE_COUNTER = 449;
export const MIN_TEXTAREA_LENGTH_VISIBLE_COUNTER_FOR_MODERATOR = 1449;
export const MAX_TEXTAREA_LENGTH_WARNING_TIMEOUT = 500;
